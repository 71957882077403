module.exports = {
    MemberBetRejectionUrl: `/memberlevelreport/memberBetRejections/{0}/{1}/{2}/{3}/{4}`,
    TheoreticalWinlossMarginUrl: `/memberlevelreport/TheoreticalWinlossMargin/{0}/{1}/{2}/{3}/{4}`,
    MemberCookieGroupingUrl: `/memberlevelreport/MemberGroupingMembersCookie/{0}/{1}/{2}/{3}`,
    AGBMemberSummaryUrl: `/memberlevelreport/AGBMemberSummary/{0}/{1}/{2}/{3}/{4}`,
    FAMemberUrl: `/memberlevelreport/FAMember/{0}/{1}/{2}/{3}/{4}/{5}`,
    MemberBehaviourChangeUrl: `/memberlevelreport/MemberBehaviourChange/{0}/{1}/{2}`,
    SystemRejectionDetailUrl: `/membersetting/SystemRejectionDetail/{0}/{1}/{2}/{3}/{4}/{5}/{6}/{7}/{8}/{9}/{10}/{11}/{12}/{13}/{14}/{15}/{16}/{17}/{18}/{19}/{20}/{21}/{22}/{23}`,
    WagerDetailUrl: `/common/WagerDetail/{0}/{1}/{2}/{3}/{4}/{5}/{6}/{7}/{8}/{9}/{10}/{11}/{12}/{13}/{14}/{15}/{16}/{17}/{18}/{19}/{20}/{21}/{22}/{23}/{24}/{25}/{26}/{27}/{28}/{29}/{30}`,
    MemberDashboardUrl: `/membersetting/memberDashboard/{0}/{1}/{2}/{3}`,
    MLTaggingWagerUrl: `/memberlevelreport/MLTaggingWagerLevel/{0}/{1}/{2}`,
    MLTagginByUIUrl: `/memberlevelreport/MLTaggingUILevel/{0}/{1}/{2}`,
    EventDashboardUrl: `/dashboard/121/{0}`,
    BetTogetherDetailUrl: `/membersetting/memberDashboard/BetTogetherDetail/{0}/{1}/{2}/{3}/{4}/{5}/{6}/{7}/{8}/{9}/{10}/{11}`,
    NotificationUrl: `/notification/{0}`,
    InternalAuditUrl: `/riskmanagement/internalAudit/{0}/{1}/{2}`,
    MemberProfilingAssignmentUrl: `/membersetting/MemberProfilingAssignment/{0}`,
    MemberLogUrl: `/membersetting/MemberLog/{0}/{1}/{2}/{3}/{4}/{5}/{6}`,
};
